
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      items: [
        {
          link: "/special/fukuoka-hotel-ryokan",
          image: "fukuokalp.png",
          alt: `${this.$t("special.fukuoka.title")}`,
        },
        {
          link: "/special/setouchi-sazanami-workation",
          image: "sw_banner.png",
          alt: `瀬戸内さざなみワーケーション`,
        },
        {
          link: "https://workation.app/",
          image: "workations.png",
          alt: `workations連泊でお得に泊まれるワーケーション先を探そう`,
        },
      ],
      isMobile: false,
    };
  },
  mounted() {
    if (!window) {
      return;
    }
    this.windowResize();
    window.addEventListener("resize", this.windowResize, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    windowResize() {
      const query = window.matchMedia("(max-width: 960px)").matches;
      this.isMobile = query;
    },
  },
});
